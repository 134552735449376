<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 674 674" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M310.823 236.984C310.823 187.692 290.483 105 154.251 105H0L116.14 304.174H12.5456V568.992H84.2088V368.962H153.852L269.261 568.992H349.67L227.381 358.326C281.414 339.919 310.823 297.528 310.823 236.984ZM193.226 299.901L116.866 169.793H154.251C229.088 169.793 239.181 203.751 239.181 236.984C239.181 272.539 225.302 291.876 193.226 299.901Z"
      fill="#652D90"
    />
    <path
      d="M630.791 337.002C659.146 314.391 674 280.333 674 237.007C674 187.716 653.649 105.028 517.417 105.028H375.718V169.811H517.412C592.249 169.811 602.347 203.78 602.347 237.007C602.347 270.234 592.249 304.197 517.412 304.197H375.718V369.866H517.412C592.249 369.866 602.347 403.824 602.347 437.056C602.347 470.288 592.249 504.247 517.412 504.247H375.718V569H517.412C653.645 569 673.996 486.303 673.996 437.012C673.996 393.656 659.161 359.602 630.787 337.016"
      fill="#652D90"
    />
  </svg>
</template>
